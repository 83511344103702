import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import CursorTrail from "./CursorTrail";
import Footer from "./components/Footer";

function WorkCard({
  imageSrc,
  title,
  description,
  isUnblurred,
  isHovered,
  link,
  index,
}) {
  const [cardHovered, setCardHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const currentRef = cardRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, index * 50);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [index]);

  return (
    <Link to={link} className="work-card-link">
      <div
        ref={cardRef}
        className={`work-card ${isUnblurred ? "unblurred" : ""} ${
          isVisible ? "visible" : ""
        }`}
        onMouseEnter={() => setCardHovered(true)}
        onMouseLeave={() => setCardHovered(false)}
      >
        <div
          className={`work-image-wrapper ${
            cardHovered || isUnblurred || isHovered ? "unblurred" : ""
          }`}
        >
          <img src={imageSrc} alt={title} className="work-image" />
        </div>
        <div
          className={`work-info ${
            cardHovered || isUnblurred || isHovered ? "unblurred" : ""
          }`}
        >
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
}

function Home() {
  const [isCursorTrailActive, setIsCursorTrailActive] = useState(false);
  const toggleCursorTrail = () => {
    setIsCursorTrailActive((prevState) => !prevState);
  };
  return (
    <CursorTrail isActive={isCursorTrailActive}>
      <div className="home-container">
        <div
          className="cursor-trail-square"
          onMouseEnter={toggleCursorTrail}
          style={{
            width: "180px",
            height: "180px",
            backgroundColor: " rgba(150, 0, 0, 1)",
            margin: "20px",
          }}
        ></div>
        <header className="app-header">
          <div className="header-content">
            <Link to="/about" className="nav-link about-link">
              ABOUT
            </Link>
            <div className="central-content">
              <h1 className="site-title">Shun Ueno</h1>
              <div className="about-section visible">
                <p className="about-text">
                Passionate about designing unique user experiences and building memorable digital products. With a background in management consulting and a double major in Design and Computer Science from Stanford, I focus on creating solutions that are user-centered and strategically thoughtful.
                </p>
              </div>
            </div>
            <a
              href="mailto:shunueno@stanford.edu"
              className="nav-link work-link"
            >
              CONTACT
            </a>

            <a
              href="mailto:shunueno@stanford.edu"
              className="icon-link email-icon"
            >
              <img src="/black_email.png" alt="Email" />
            </a>
            <a
              href="https://linkedin.com/in/shunueno"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link linkedin-icon"
            >
              <img src="/linkedin.png" alt="LinkedIn" />
            </a>
          </div>
        </header>
        <hr className="divider" />
        <section id="works" className="works-section">
          <div className="works-container">
            {[
              {
                imageSrc: "/actormockup8.png",
                title: "Actor To Actor Game",
                link: "/works/actortoactor",
                description: 'Software Engineering'
              },
              {
                imageSrc: "/radar2.png",
                title: "VALUENEX",
                link: "/works/valuenex",
                description: 'Consulting'
              },
              {
                imageSrc: "/pwr.png",
                title: "Hostile Architecture and Inaccessibility",
                link: "/works/hostile-architecture",
                description: 'Design Research'

              },
              {
                imageSrc: "/poster_mockup3.png",
                title: "ECLIPSE",
                link: "/works/eclipse",
                description: 'Visual Design'
              },
              /*
              {
                imageSrc: "/rockpath2.png",
                title: "Miscellaneous Side Projects",
                link: "/works/justforfun",
              },
              {
                imageSrc: "/frag2.png",
                title: "FRGNCE",
              },*/
            ].map((card, index) => (
              <WorkCard
                key={card.title}
                imageSrc={card.imageSrc}
                title={card.title}
                description={card.description}
                isUnblurred={true}
                isHovered={false}
                link={card.link}
                index={index}
              />
            ))}
          </div>
        </section>
        <hr className="divider" />
        <Footer />
      </div>
    </CursorTrail>
  );
}

export default Home;
