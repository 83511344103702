// src/VNPage.js
import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';

import PageNavigator from './components/PageNavigator';
import BackButton from "./components/BackButtonBlack";
import './VNPage.css';

function VNPage() {
  // Animation variant for each group with blur effect
  const groupAnimation = {
    hidden: { opacity: 0, y: 0, filter: "blur(10px)" },
    visible: { opacity: 1, y: 0, filter: "blur(0px)" }
  };

  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen width is mobile-sized on component mount
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="vn-page">
      
      {!isMobile && <PageNavigator/>}
      {isMobile && <BackButton/>}
      <h1 className="vn-title">VALUENEX</h1>
      <div className="vn-image-container">
        <img src="/vn_new2.png" alt="VN RADAR" className="vn-image" />
      </div>
      <div className="vn-content">
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">November 2023 - Present</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">Python, AWS, NLP, Sklearn</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Big Data Analysis, Consulting</span>
            </div>
            <div className="project-detail">
              <span className="label">Role:</span>
              <span className="value">SWE and Data Analyst Intern</span>
            </div>
          </div>
        </div>
        
        <hr className="divider" />

        {/* Animated Sections */}
        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <p>During my ongoing internship at{' '}
            <a 
              href="https://en.valuenex.com/" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              VALUENEX, Inc
            </a>
             {' '} as a Software Engineering and Data Analyst intern, I have led full-stack and data-driven initiatives focused on optimizing client processes and supporting strategic decision-making.
          </p>
        </motion.div>

        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Key Contributions</h2>
          <ul>
            <li><strong>Scalable Full-Stack Development</strong>: Designed and implemented a scalable AI-powered program using GPT API, Python, and AWS, achieving a 150% increase in efficiency for component search and vertical integration tasks. This application catered to the needs of major manufacturing clients, streamlining processes and enhancing productivity.</li>
            <li><strong>Natural Language Processing & Cluster Mapping</strong>: Developed NLP and cluster mapping solutions using Python and Sklearn, providing predictive analytics and big data visualization tools that delivered actionable insights for over 10 high-profile clients across diverse industries.</li>
            <li><strong>Data-Driven Consulting</strong>: Led data-driven decision-making consultations for a top Japanese automotive manufacturer, analyzing market trends and new business opportunities</li>
            <li><strong>Bilingual Client Communication</strong>: Played a key role in client interactions by facilitating effective communication between English and Japanese-speaking clients, ensuring accurate and clear understanding of project objectives and results.</li>
          </ul>
        </motion.div>

        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Technical Highlights</h2>
          <ul>
            <li><strong>Python & Sklearn</strong>: Used Python for backend development, machine learning, and data analysis tasks, leveraging Sklearn for implementing robust NLP and clustering models.</li>
            <li><strong>AWS</strong>: Deployed and scaled applications on AWS, enabling seamless access and integration for enterprise-level use.</li>
            <li><strong>NLP & Predictive Analytics</strong>: Applied NLP techniques for extracting insights from textual data, while also delivering data visualization solutions to enhance client decision-making.</li>
          </ul>
        </motion.div>

        <motion.div
          className="vn-description"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          transition={{ duration: 0.5 }}
          variants={groupAnimation}
        >
          <h2>Outcomes & Learnings</h2>
          <ul>
            <li><strong>New Business Development</strong>: Successfully developed market entry strategies for various clients using big data predictive analytics.</li>
            
          
            <li><strong>Stakeholder Communication and Business Skills</strong>: Enhanced my ability to communicate effectively with stakeholders by translating technical insights into business value, facilitating informed decision-making and aligning project outcomes with business goals.</li>

          
          </ul>
        </motion.div>
      </div>
    </div>
  );
}

export default VNPage;
