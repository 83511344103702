// src/components/BackButton.js
import { useNavigate } from "react-router-dom";
import './BackButton.css';

function BackButton() {
  const navigate = useNavigate();

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent default link navigation

    if (window.history.length > 1) {
      // Go back if there is history
      navigate(-1);
    } else {
      // Otherwise, navigate to the homepage
      navigate("/");
    }
  };

  return (
    <button className="back-button" onClick={handleLinkClick}>
        <img src="/black_back.png" alt="Back" className="back-arrow-icon" />
    </button>
  );
}

export default BackButton;
