// src/WorkPage.js
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import HostileArchitecturePage from './HostileArchitecturePage';
import EclipsePage from './EclipsePage';
import VNPage from './VNPage';
import MovieGamePage from './MovieGamePage';
import FragrancePage from './FragrancePage';
import JFFPage from './JFFPage';

function WorkPage() {
  const { workId } = useParams();

  switch(workId) {
    case 'hostile-architecture':
      return <HostileArchitecturePage />;
    case 'eclipse':
      return <EclipsePage />;
    case 'valuenex':
      return <VNPage />;
    case 'justforfun':
      return <JFFPage />;
    case 'actortoactor':
      return <MovieGamePage />;
    case 'fragrance-ai':
      return <FragrancePage />;
    default:
      return <Navigate to="/" replace />;
  }
}

export default WorkPage;