import { Link, useNavigate } from "react-router-dom";

function PageNavigator() {
  const navigate = useNavigate();

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent default link navigation

    if (window.history.length > 1) {
      // Go back if there is history
      navigate(-1);
    } else {
      // Otherwise, navigate to the homepage
      navigate("/");
    }
  };

  return (
    <header className="about-page-header">
      <Link to="/" className="about-page-link" onClick={handleLinkClick}>
        Shun Ueno
      </Link>
    </header>
  );
}

export default PageNavigator;
