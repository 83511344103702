// cursortrail.js
import React, { useEffect, useRef } from 'react';

const CursorTrail = ({ children, isActive }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const positionsRef = useRef([]);
  const requestIdRef = useRef(null);
  const isTrailActiveRef = useRef(false);

  // Update isTrailActiveRef when isActive changes
  useEffect(() => {
    isTrailActiveRef.current = isActive;
    if (!isActive) {
      positionsRef.current = [];
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [isActive]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      const { scrollWidth, scrollHeight } = document.documentElement;

      canvas.width = scrollWidth * dpr;
      canvas.height = scrollHeight * dpr;

      canvas.style.width = `${scrollWidth}px`;
      canvas.style.height = `${scrollHeight}px`;

      // Reset the transform before scaling
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.scale(dpr, dpr);
    };

    const addPosition = (x, y) => {
      positionsRef.current.push({ x, y });
      if (positionsRef.current.length > 1000) {
        positionsRef.current.shift();
      }
    };

    const drawTrail = () => {
      if (!isTrailActiveRef.current) {
        requestIdRef.current = requestAnimationFrame(drawTrail);
        return;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      positionsRef.current.forEach((position) => {
        ctx.fillStyle = `rgba(150, 0, 0, 1)`;
        ctx.beginPath();
        ctx.arc(position.x, position.y, 3, 0, Math.PI * 2);
        ctx.fill();
      });
      requestIdRef.current = requestAnimationFrame(drawTrail);
    };

    const mouseMoveHandler = (event) => {
      if (!isTrailActiveRef.current) return;
      const x = event.pageX;
      const y = event.pageY;
      addPosition(x, y);
    };

    // Create a ResizeObserver to watch for container size changes
    const resizeObserver = new ResizeObserver(() => {
      resizeCanvas();
    });

    resizeObserver.observe(container);

    window.addEventListener('resize', resizeCanvas);
    document.addEventListener('mousemove', mouseMoveHandler);

    // Initial resize and start drawing
    resizeCanvas();
    drawTrail();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      document.removeEventListener('mousemove', mouseMoveHandler);
      cancelAnimationFrame(requestIdRef.current);
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to run once

  return (
    <div ref={containerRef} style={{ position: 'relative', minHeight: '100vh' }}>
      {children}
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          zIndex: 1,
        }}
      />
    </div>
  );
};

export default CursorTrail;