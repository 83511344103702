import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import BackButton from "./components/BackButtonBlack";
import PageNavigator from "./components/PageNavigator";
import "./MovieGamePage.css";

function MovieGamePage() {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen width is mobile-sized on component mount
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Animation variant for desktop, non-animated display for mobile
  const groupAnimation = isMobile
    ? { hidden: { opacity: 1, y: 0, filter: "blur(0px)" }, visible: { opacity: 1, y: 0, filter: "blur(0px)" } }
    : {
        hidden: { opacity: 0, y: 0, filter: "blur(10px)" },
        visible: { opacity: 1, y: 0, filter: "blur(0px)" }
      };

  return (
    <div className="movie-game-page">
      {!isMobile && <PageNavigator/>}
      {isMobile && <BackButton/>}
      <a
          href="https://actortoactor.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="play-game-button">Play the Game</button>
        </a>
      <h1 className="movie-game-title">Actor to Actor Game</h1>
      <div className="movie-image-container">
        <img
          src="/actormockup9.png"
          alt="Actor to Actor game"
          className="movie-image"
        />
        
      </div>

      <div className="movie-game-content">
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">June 2024 - October 2024</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">
                React, Python, JavaScript
              </span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Full-stack Development, UI/UX Designer</span>
            </div>
            <div className="project-detail">
              <span className="label">Role:</span>
              <span className="value">
                Personal Project
              </span>
            </div>
          </div>
        </div>

        <hr className="divider" />

        {/* Animated Sections */}
        {/* Conditionally render motion.div or div based on screen size */}
        {isMobile ? (
          <div className="movie-game-description">
            <h2>Project Summary</h2>
            <p>
              The Actor to Actor Game is an interactive web app that challenges
              players to connect two actors through a network of movies and
              co-stars. Players navigate a dynamically changing path between the
              starting and target actors, with incorrect guesses increasing the
              challenge by recalculating a longer path. This project, inspired by
              the "Six Degrees of Kevin Bacon" theory, combines game design,
              algorithmic problem-solving, and UI/UX principles to offer users a
              fun and intellectually stimulating experience.
            </p>
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Project Summary</h2>
            <p>
              The Actor to Actor Game is an interactive web app that challenges
              players to connect two actors through a network of movies and
              co-stars. Players navigate a dynamically changing path between the
              starting and target actors, with incorrect guesses increasing the
              challenge by recalculating a longer path. This project, inspired by
              the "Six Degrees of Kevin Bacon" theory, combines game design,
              algorithmic problem-solving, and UI/UX principles to offer users a
              fun and intellectually stimulating experience.
            </p>
          </motion.div>
        )}

        {/* Repeat the above pattern for other sections */}
        {isMobile ? (
          <div className="movie-game-description">
            <h2>Core Responsibilities</h2>
            <ul>
              <li>
              <strong>UI/UX Design</strong>: Designed an intuitive, responsive user interface in
                React, focusing on seamless user interaction, accessibility, and
                visual appeal.
              </li>
              <li>
              <strong>Backend Development</strong>: Built a robust backend using Flask to handle
                game logic, state management, and API integration.
              </li>
              <li>
              <strong>Game Mechanics & Algorithms</strong>: Implemented Dijkstra’s algorithm to
                calculate the shortest path between actors, adding a strategic
                layer to gameplay.
              </li>
              <li>
              <strong>API Integrations</strong>: Integrated TMDb API for actor images, enhancing
                the user experience, and OpenAI’s GPT model to provide subtle
                hints for players needing assistance.
              </li>
            </ul>
          </div>
        ) : (
          <motion.div
            className="movie-game-description"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            transition={{ duration: 0.5 }}
            variants={groupAnimation}
          >
            <h2>Core Responsibilities</h2>
            <ul>
              <li>
              <strong>UI/UX Design</strong>: Designed an intuitive, responsive user interface in
                React, focusing on seamless user interaction, accessibility, and
                visual appeal.
              </li>
              <li>
              <strong>Backend Development</strong>: Built a robust backend using Flask to handle
                game logic, state management, and API integration.
              </li>
              <li>
              <strong>Game Mechanics & Algorithms</strong>: Implemented Dijkstra’s algorithm to
                calculate the shortest path between actors, adding a strategic
                layer to gameplay.
              </li>
              <li>
              <strong>API Integrations</strong>: Integrated TMDb API for actor images, enhancing
                the user experience, and OpenAI’s GPT model to provide subtle
                hints for players needing assistance.
              </li>
            </ul>
          </motion.div>
        )}


{isMobile ? (
  <div className="movie-game-description">
    <h2>Detailed Process</h2>
    <h3>1. Concept & Game Inspiration</h3>
    <p>
      The idea is rooted in the "Six Degrees of Kevin Bacon" theory, aiming to engage users by challenging their movie knowledge and logical thinking. Players must guess connections between randomly chosen actors via co-stars, dynamically testing their movie network knowledge.
    </p>
    <h3>2. Design & User Experience</h3>
    <p>
      Designed a responsive and visually engaging interface in React, ensuring that players could navigate effortlessly and receive real-time feedback on their progress. Leveraged the TMDb API for immersive gameplay, displaying actor images at each step.
    </p>
    <h3>3. Backend & Game Logic</h3>
    <ul>
      <li><strong>Framework</strong>: Chose Flask for its lightweight and effective session management, setting up API endpoints for smooth interaction with the frontend.</li>
      <li><strong>Path Calculation</strong>: Developed the core pathfinding mechanic with Dijkstra's algorithm using Python's NetworkX to create a graph of actors and movies, treating actors and movies as nodes and edges based on collaborations.</li>
      <li><strong>Data Storage</strong>: Preprocessed and stored the graph in a pickle file for efficient loading and manipulation.</li>
    </ul>
    <h3>4. Frontend Integration & Smooth API Communication</h3>
    <p>
      Built the React frontend to communicate seamlessly with the Flask backend through RESTful API calls, ensuring consistent and real-time gameplay. Players see dynamic updates with each guess and enjoy smooth transitions between actions.
    </p>
    <h3>5. Key Gameplay Features</h3>
    <ul>
      <li><strong>Dynamic Path Updates</strong>: Incorrect guesses trigger the recalculation of the shortest path, creating an adaptive and engaging experience.</li>
      <li><strong>Hint System</strong>: Players can access hints powered by OpenAI's GPT, adding an interactive helper without giving away answers directly.</li>
    </ul>
  </div>
) : (
  <motion.div
    className="movie-game-description"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false, amount: 0.3 }}
    transition={{ duration: 0.5 }}
    variants={groupAnimation}
  >
    <h2>Detailed Process</h2>
    <h3>1. Concept & Game Inspiration</h3>
    <p>
      The idea is rooted in the "Six Degrees of Kevin Bacon" theory, aiming to engage users by challenging their movie knowledge and logical thinking. Players must guess connections between randomly chosen actors via co-stars, dynamically testing their movie network knowledge.
    </p>
    <h3>2. Design & User Experience</h3>
    <p>
      Designed a responsive and visually engaging interface in React, ensuring that players could navigate effortlessly and receive real-time feedback on their progress. Leveraged the TMDb API for immersive gameplay, displaying actor images at each step.
    </p>
    <h3>3. Backend & Game Logic</h3>
    <ul>
      <li><strong>Framework</strong>: Chose Flask for its lightweight and effective session management, setting up API endpoints for smooth interaction with the frontend.</li>
      <li><strong>Path Calculation</strong>: Developed the core pathfinding mechanic with Dijkstra's algorithm using Python's NetworkX to create a graph of actors and movies, treating actors and movies as nodes and edges based on collaborations.</li>
      <li><strong>Data Storage</strong>: Preprocessed and stored the graph in a pickle file for efficient loading and manipulation.</li>
    </ul>
    <h3>4. Frontend Integration & Smooth API Communication</h3>
    <p>
      Built the React frontend to communicate seamlessly with the Flask backend through RESTful API calls, ensuring consistent and real-time gameplay. Players see dynamic updates with each guess and enjoy smooth transitions between actions.
    </p>
    <h3>5. Key Gameplay Features</h3>
    <ul>
      <li><strong>Dynamic Path Updates</strong>: Incorrect guesses trigger the recalculation of the shortest path, creating an adaptive and engaging experience.</li>
      <li><strong>Hint System</strong>: Players can access hints powered by OpenAI's GPT, adding an interactive helper without giving away answers directly.</li>
    </ul>
  </motion.div>
)}

{isMobile ? (
  <div className="movie-game-description">
    <h2>Technical Highlights</h2>
    <ul>
      <li><strong>Python & Flask</strong>: Managed game logic, session handling, and API interactions.</li>
      <li><strong>Dijkstra’s Algorithm</strong>: Calculated shortest paths dynamically, using NetworkX to model actors and movies as a graph.</li>
      <li><strong>React</strong>: Designed a responsive, intuitive frontend to enhance user engagement.</li>
      <li><strong>API Integrations</strong>: Leveraged TMDb API for actor images and OpenAI API for intelligent hinting, both adding depth and interaction to the gameplay.</li>
      <li><strong>Pickle</strong>: Stored and accessed preprocessed game data, optimizing performance.</li>
    </ul>
  </div>
) : (
  <motion.div
    className="movie-game-description"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false, amount: 0.3 }}
    transition={{ duration: 0.5 }}
    variants={groupAnimation}
  >
    <h2>Technical Highlights</h2>
    <ul>
      <li><strong>Python & Flask</strong>: Managed game logic, session handling, and API interactions.</li>
      <li><strong>Dijkstra’s Algorithm</strong>: Calculated shortest paths dynamically, using NetworkX to model actors and movies as a graph.</li>
      <li><strong>React</strong>: Designed a responsive, intuitive frontend to enhance user engagement.</li>
      <li><strong>API Integrations</strong>: Leveraged TMDb API for actor images and OpenAI API for intelligent hinting, both adding depth and interaction to the gameplay.</li>
      <li><strong>Pickle</strong>: Stored and accessed preprocessed game data, optimizing performance.</li>
    </ul>
  </motion.div>
)}

{isMobile ? (
  <div className="movie-game-description">
    <h2>Outcomes & Learnings</h2>
    <ul>
      <li><strong>User Engagement</strong>: Successfully balanced game difficulty through dynamic path recalculations and a hint system, enhancing retention and user engagement.</li>
      <li><strong>Enhanced Skills</strong>: Deepened expertise in full-stack development, API integration, and algorithm design, while honing my UI/UX capabilities for creating intuitive and visually appealing interfaces.</li>
    </ul>
  </div>
) : (
  <motion.div
    className="movie-game-description"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false, amount: 0.3 }}
    transition={{ duration: 0.5 }}
    variants={groupAnimation}
  >
    <h2>Outcomes & Learnings</h2>
    <ul>
      <li><strong>User Engagement</strong>: Successfully balanced game difficulty through dynamic path recalculations and a hint system, enhancing retention and user engagement.</li>
      <li><strong>Enhanced Skills</strong>: Deepened expertise in full-stack development, API integration, and algorithm design, while honing my UI/UX capabilities for creating intuitive and visually appealing interfaces.</li>
    </ul>
  </motion.div>
)}

      </div>
    </div>
  );
}

export default MovieGamePage;
