import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './About.css';



function About() {
  // Animation variant for each group with blur effect
  const groupAnimation = {
    hidden: { opacity: 0, y: 10, filter: "blur(10px)" },
    visible: { opacity: 1, y: 0, filter: "blur(0px)" }
  };

  return (
    <div className="about-page-container">
      {/* Navigation Button to Go Back to Homepage */}
      <header className="about-page-header">
        <Link to="/" className="about-page-link">
          Shun Ueno
        </Link>
      </header>

      {/* Main Content */}
      <main className="about-page-content">
        
        {/* Section 1 */}
        <motion.div
          className="about-page-section"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.2 }}  // Triggers animation when 50% of section is in view
          transition={{ duration: 0.7 }}
          variants={groupAnimation}
        >
          <img src="/london.webp" alt="London" className="about-page-image" />
          <p className="about-page-caption">London, UK</p>
          <p className="about-page-text">
          Hello! I'm Shun – a designer and developer focused on creating unique, interactive digital experiences. With a background in Design and Computer Science from Stanford, I approach projects with a dual lens, merging aesthetic design with robust functionality.
          </p>
          <p className="about-page-text">
          I enjoy working at the intersection of creativity and technology, where code becomes a medium for storytelling and design transforms into an immersive experience. I am experienced in full-stack development and passionate about creative coding, continually exploring new ways to advance digital experiences.
          </p>
          <p className="about-page-text">
          My background in management consulting has equipped me with a strategic mindset and the ability to engage and communicate effectively with stakeholders. I’m skilled at aligning design goals with business objectives, ensuring that each project not only meets user needs but also delivers measurable value for clients.
          </p>
        </motion.div>

        {/* Section 2 */}
        <motion.div
          className="about-page-section"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.25 }}
          transition={{ duration: 0.7 }}
          variants={groupAnimation}
        >
          <img src="/window2.png" alt="Adachi Museum" className="about-page-image" />
          <p className="about-page-caption">Adachi Museum, Japan</p>
          <p className="about-page-text">
            Growing up in Tokyo, I was surrounded by Japanese design's simplicity and careful detail.
            This influence drives me to create work that feels balanced, purposeful, and thoughtfully crafted.
          </p>
        </motion.div>

        {/* Section 3 */}
        <motion.div
          className="about-page-section"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.4 }}
          transition={{ duration: 0.7 }}
          variants={groupAnimation}
        >
          <img src="/lille2.jpeg" alt="Lille" className="about-page-image" />
          <p className="about-page-caption">Lille, France</p>
          <p className="about-page-text">
            My life experiences have given me a unique perspective on designing spaces that are thoughtful and inclusive.
            This background shapes my commitment to creating interfaces that feel intuitive and welcoming,
            with a deep respect for diverse user needs and perspectives.
          </p>
        </motion.div>
      </main>

      
    </div>
  );
}

export default About;
