// src/JFFPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './JFFPage.css';
import BackButton from './components/BackButton';
import { ReactComponent as Rock1SVG } from './rock1.svg';

const ideas = [
    {
        name: "Landolt C Game",
        component: Rock1SVG,
        link: "/prescription",
        position: { top: '10%', left: '5%' }
    }
    // Add more ideas here with their respective SVG components and positions
];

function JFFPage() {
    return (
        <div className="jff-page">
            <BackButton />
            <h1 className="jff-title">Miscellaneous</h1>
            <div className="jff-image-container">
                {ideas.map((idea, index) => (
                    <Link
                        key={index}
                        to={idea.link}
                        className="jff-svg-link"
                        style={{
                            position: 'absolute',
                            top: idea.position.top,
                            left: idea.position.left
                        }}
                    >
                        <idea.component className="jff-svg" />
                        <div className="jff-overlay">
                            <span className="jff-idea-name">{idea.name}</span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default JFFPage;